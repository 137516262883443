import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const OximoWidgetPage = () => {
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === "oximo" && event.data.param === "height") {
        const iframe = document.getElementById("oximo-iframe");
        if (iframe) {
          // Update the iframe's height based on the value received.
          iframe.style.height = event.data.value;
        }
      }
    };

    window.addEventListener("message", handleMessage);

    // Cleanup on component unmount.
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <div>
      <Navbar />
      <Container>
        <iframe
          id="oximo-iframe"
          style={{ width: "100%", border: "none" }}
          src="https://www.oximo.pl/widget/?HTTP_REFERER=https://kamsiparts.mazesai.com/"
          title="Oximo Widget"
        ></iframe>
      </Container>
      <Footer />
    </div>
  );
};

export default OximoWidgetPage;
